import React, { useEffect, useState } from "react";
import "./Footer.css";
import Logo from "./logo1.svg"
// import Logo from "../../../assets/images/rent_imgLogo.png";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

import API from "../../../api";
import axios from "axios";

export default function Footer() {
  const { t } = useTranslation();
  const [cats, setCats] = useState([]);

  useEffect(() => {
    try {
      axios.get(`${API}/categories`).then((res) => {
        setCats(res.data.data);
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  console.log(cats);

  const currentLanguage = i18next.language;
  return (
    <>
      <div className="footer">
        <div className="container footer-wrapper">
          <div className="footer_box">
            <div className="footer_item_box footer_item_text_box_res">
              <div>
                <img className="footer_logo" src={Logo} alt="" />
              </div>
              <div>
                <p className="footer_item_subtext">{t("rentTitle")}</p>
                <p className="footer_item_text">{t("footerText")}</p>
              </div>
              <div className="footer_bottom_res">
                <div className="footer_box_bottom footer_box_bottom_res">
                  <div className="footer_bottom_box">
                    <a className="footer_bootom" href="tel:+971527030189">
                      {t("getOffer")}
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="footer_col">
              <div className="footer_item_box">
                <div className="footer_link_box">
                  <NavLink className="footer_title">{t("cars")}</NavLink>
                  <ul className="footer_link_menu_cars">
                    {cats.map((item, i) => {
                      return (
                        <li key={i} className="menu_cars_footer">
                          <NavLink
                            onClick={() => window.scrollTo({ top: 0 })}
                            to={`cars/${item?.id}`}
                            className="footer__links"
                          >
                            {item[`name_${currentLanguage}`]}
                          </NavLink>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
              <div className="footer_item_box">
                <div className="footer_link_box">
                  <NavLink
                    to="/blog"
                    onClick={() => window.scrollTo({ top: 0 })}
                    className="footer_title"
                  >
                    {t("blog")}
                  </NavLink>
                  <NavLink
                    onClick={() => window.scrollTo({ top: 0 })}
                    className="footer_title"
                    to="/service"
                  >
                    {t("services")}
                  </NavLink>
                  <div className="footer_contact_box">
                    <NavLink
                      onClick={() => window.scrollTo({ top: 0 })}
                      to="/contact"
                      className="footer_title"
                    >
                      {t("contacts")}
                    </NavLink>
                    <span className="footer_info_text">
                      Elite 3 Sports City, Dubai 26W8 24J, United Arab Emirates<br />
                      +971 52 7030189
                      <br />
                      {t("hours")}{" "}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="footer_item_box footer_item_box_df">
              <div className="footer_about_box">
                <NavLink
                  onClick={() => window.scrollTo({ top: 0 })}
                  to="/about_us"
                  className="footer_title"
                >
                  {t("aboutUs")}
                </NavLink>
                <ul className="footer_link_menu_cars">
                  <li className="menu_cars_footer">
                    <NavLink
                      to="our_team"
                      onClick={() => window.scrollTo({ top: 0 })}
                      className="footer__links"
                    >
                      {t("ourTeam")}
                    </NavLink>
                  </li>
                  <li className="menu_cars_footer">
                    <NavLink
                      to="/faq"
                      onClick={() => window.scrollTo({ top: 0 })}
                      className="footer__links"
                    >
                      {t("faqFooter")}
                    </NavLink>
                  </li>
                </ul>
              </div>
              <div className="footer_follow_box">
                <NavLink className="footer_title">{t("follow")}</NavLink>
                <div className="footer_social">
                  <NavLink
                    target={"_blank"}
                    to={"https://www.instagram.com/autozoomrental/"}
                  >
                    <svg
                      width="18"
                      height="19"
                      viewBox="0 0 18 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5 0.487793C2.243 0.487793 0 2.73079 0 5.48779V13.4878C0 16.2448 2.243 18.4878 5 18.4878H13C15.757 18.4878 18 16.2448 18 13.4878V5.48779C18 2.73079 15.757 0.487793 13 0.487793H5ZM5 2.48779H13C14.654 2.48779 16 3.83379 16 5.48779V13.4878C16 15.1418 14.654 16.4878 13 16.4878H5C3.346 16.4878 2 15.1418 2 13.4878V5.48779C2 3.83379 3.346 2.48779 5 2.48779ZM14 3.48779C13.7348 3.48779 13.4804 3.59315 13.2929 3.78069C13.1054 3.96822 13 4.22258 13 4.48779C13 4.75301 13.1054 5.00736 13.2929 5.1949C13.4804 5.38244 13.7348 5.48779 14 5.48779C14.2652 5.48779 14.5196 5.38244 14.7071 5.1949C14.8946 5.00736 15 4.75301 15 4.48779C15 4.22258 14.8946 3.96822 14.7071 3.78069C14.5196 3.59315 14.2652 3.48779 14 3.48779ZM9 4.48779C6.243 4.48779 4 6.73079 4 9.48779C4 12.2448 6.243 14.4878 9 14.4878C11.757 14.4878 14 12.2448 14 9.48779C14 6.73079 11.757 4.48779 9 4.48779ZM9 6.48779C10.654 6.48779 12 7.83379 12 9.48779C12 11.1418 10.654 12.4878 9 12.4878C7.346 12.4878 6 11.1418 6 9.48779C6 7.83379 7.346 6.48779 9 6.48779Z"
                        fill="white"
                        fillOpacity="0.6"
                      ></path>
                    </svg>
                  </NavLink>
                  <NavLink
                  target={"_blank"}
                  to={"https://www.instagram.com/autozoomrental/"}
                  >
                    <svg
                      width="20"
                      height="19"
                      viewBox="0 0 20 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M2.80519 2.98366C4.60132 1.24739 7.0374 0.271973 9.57752 0.271973C12.1176 0.271973 14.5537 1.24739 16.3499 2.98366C18.146 4.71992 19.155 7.0748 19.155 9.53024C19.155 11.9857 18.146 14.3406 16.3499 16.0768C14.8621 17.515 12.9353 18.4311 10.874 18.7033V11.9842H13.3526L13.7417 9.55024H10.8735V8.21997C10.8735 7.20887 11.2153 6.31228 12.1937 6.31228H13.7659V4.18824L13.7511 4.1863C13.469 4.14943 12.8861 4.07324 11.8015 4.07324C9.49639 4.07324 8.14495 5.25002 8.14495 7.93102V9.55024H5.77527V11.9842H8.14495V18.6844C6.13501 18.3905 4.26014 17.4833 2.80519 16.0768C1.00906 14.3406 0 11.9857 0 9.53024C0 7.0748 1.00906 4.71992 2.80519 2.98366Z"
                        fill="white"
                        fillOpacity="0.6"
                      ></path>
                    </svg>
                  </NavLink>
                  <NavLink>
                    <svg
                      width="22"
                      height="17"
                      viewBox="0 0 22 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M19.0065 15.9746C20.1376 15.813 21.0532 14.9513 21.2687 13.8202C21.4841 12.5814 21.6996 10.7501 21.7534 8.48789C21.7534 6.22571 21.4841 4.39443 21.2687 3.15562C21.0532 2.02453 20.1376 1.16275 19.0065 1.00117C17.1752 0.73186 14.2667 0.408691 10.9273 0.408691C7.58789 0.408691 4.62552 0.73186 2.8481 1.00117C1.71701 1.16275 0.801365 2.02453 0.58592 3.15562C0.370475 4.39443 0.155029 6.22571 0.155029 8.48789C0.155029 10.7501 0.370475 12.5814 0.58592 13.8202C0.801365 14.9513 1.71701 15.813 2.8481 15.9746C4.67938 16.2439 7.64175 16.5671 10.9273 16.5671C14.2667 16.5671 17.2291 16.2439 19.0065 15.9746ZM15.2362 8.48804L8.77281 4.71774V12.2583L15.2362 8.48804Z"
                        fill="white"
                        fillOpacity="0.6"
                      ></path>
                    </svg>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
          <div className="footer_box">
            <div className="footer_box_bottom footer_box_bottom_res">
              <div className="footer_bottom_box">
                <a className="footer_bootom" href="tel:+971527030189">
                  {t("getOffer")}
                </a>
              </div>
            </div>
            <div className="footer_box_bottom footer_term_box">
              <div className="footer__bottom">
                <p className="footer__links">© 2024   Auto Zoom Car Rental. {t("rights")}</p>
              </div>
              <div className="footer__bottom_links">
                <NavLink
                  onClick={() => window.scrollTo({ top: 0 })}
                  to="/terms_and_conditions"
                  className="footer__links"
                >
                  {t("terms")}
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
