import React from 'react';
import "./Contact.css";
import { Link } from 'react-router-dom';

import locationsvg from "../../assets/svg/location.svg";
import tel from "../../assets/svg/tel.svg";
import mail from "../../assets/svg/mail.svg";
import { useTranslation } from "react-i18next"
import Location from '../Location/Location';

export default function Contact() {

  const { t } = useTranslation()
  return (
    <>
      <div className="contact">
        <div className="container">
          <Link className="contact_nav_link" to="/">{t('contactTitle1')} &nbsp; / &nbsp; <span>{t('contactTitle2')}</span> </Link>
          <h1 className='contac_title'>{t('haveQuestions')}</h1>
          <p className='contact_text'>
            {t('wouldHelp')}<br />
            {t('contactText')}
          </p>
          <div className="contact_box">
            <h2 className='contact_box_title'>{t('headOffice')}</h2>
            <ul>
              <li>
                <img src={locationsvg} alt="Loc" />
                <p>Elite 3 Sports City, Dubai 26W8 24J, United Arab Emirates</p>
              </li>
              <li>
                <img src={tel} alt="Makamov|Dev" />
                <a href="tel:971527030189">+971 52 7030189</a>
              </li>
              <li>
                <img src={mail} alt="Loc" />
                <a href="mailto:office@autozoomrental.com">office@autozoomrental.com</a>
              </li>
            </ul>
          </div>
          <div className="contact_maps"> 
              <iframe className='contact_maps_map' src="https://www.google.com/maps/embed?pb=!1m13!1m8!1m3!1d7229.364749795036!2d55.215299!3d25.044851!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjXCsDAyJzQxLjUiTiA1NcKwMTInNTUuMSJF!5e0!3m2!1sen!2s!4v1703261946327!5m2!1sen!2s" width="900" height="500" title='map'  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          </div>
        </div>
      </div>
      {/* <Location /> */}
    </>
  )
}
