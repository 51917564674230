import React from "react";
import "./VideoPage.css";
import { Link } from "react-router-dom";
import {useTranslation} from "react-i18next"

function VideoPage() {

  const { t} = useTranslation();
  return (
    <>
      <div className="videopage">
        <div className="container">
          <div className="videopage_flex">
            <div className="video_part">
              <div className="video_iframe">
                <iframe
                  height="315"
                  frameBorder={0}
                  src="https://www.youtube.com/embed/6Bcg46rxqAE?si=oUOIn81REX0sL4RT&amp;start=2"
                  title="Auto Zoom Car Rental - Video"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
            <div className="description_part">
              <h2 className="videopage_title">{t("sportRent")}</h2>
              <p className="videopage_text">
                {t('sportText')}
              </p>

              <Link onClick={() =>
                  window.scrollTo({ top: 0 })
              } to={'/cars'} className="more_link_lc">
                <span className="luxury_comp_link_flex">
                  {t('allCars')}
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13 10L21 16.5L13 23L17.5714 16.5L13 10Z"
                      fill="white"
                    ></path>
                    <circle cx="16" cy="16" r="15.5" stroke="white"></circle>
                  </svg>
                </span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default VideoPage;
