import React from 'react'
import Home from './pages/Home/Home'
import Models from './pages/Models/Models'
import Changecars from './pages/Changecars/Changecars'
import ServicePage from "./pages/Service/ServicePageForHome";
import Luxurydesc from './pages/Luxury/Luxurydesc';
import VideoPage from './pages/VideoPage/VideoPage';
import Lambopart from './pages/Lambopart/Lambopart';
import FollowInstagram from './pages/FollowIsntageram/FollowInstagram';
import FaqPageForHome from './pages/FaqPage/FaqPageForHome';
import Location from './pages/Location/Location';
import Carinfo from './pages/Carinfo/Carinfo';

export default function HomePageCollector() {
  return (
    <>
      <Home />
      <Models />
      <Changecars />
      <ServicePage />
      <Luxurydesc />
      <VideoPage />
      <Lambopart />
      <FaqPageForHome/>
      <FollowInstagram />
      <Location/>
    </> 
  )
}