import React from 'react'
import './FaqPage.css';

import { NavLink } from 'react-router-dom';
import Location from '../Location/Location';
import Accordion from '../../components/Accardion/Accardion';
import { useTranslation } from 'react-i18next';

export default function FaqPage() {
  const {t} = useTranslation()
  return (
    <>
        <div className="faq">
            <div className="container">
                <NavLink to="/" className="ser_page_link" >{t('rentTitle')} / <span className='ser_page_link' >{t('faq')}</span> </NavLink>
                <h1 className='ser_subtitle' >{t("faqLong")}</h1>
                <Accordion/>
            </div>
            {/* <Location/> */}
        </div>
    </>
  )
}
