import axios from "axios";
import React, { useEffect, useState } from "react";
import "./BrandsSwiper.css";

import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/grid";
import "swiper/css/pagination";
import { useTranslation } from "react-i18next";

import { Grid, Pagination } from "swiper/modules";
import API from "../../../api";
import { Skeleton } from "@mui/material";

function BrandsSwiper() {
  const [brands, setBrands] = useState([]);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios
      .get(`${API}/brands/`)
      .then((res) => {
        setBrands(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    const loadingTimeout = setTimeout(() => {
      setLoading(false);
    }, 2000);
    return () => clearTimeout(loadingTimeout);
  }, []);

  return (
    <>
      <div className="brandsswiper">
        <div className="container">
          <h2 className="brands_title">{t("brandsTitle")}</h2>
          <div className="brandsswiper_flex">
            <Swiper
              slidesPerView={6}
              spaceBetween={25}
              breakpoints={{
                350: {
                  slidesPerView: 2,
                  spaceBetween: 40,
                  grid: {
                    rows: 2,
                  },
                },
                700: {
                  slidesPerView: 2,
                  spaceBetween: 40,
                },
                900: {
                  slidesPerView: 4,
                  spaceBetween: 40,
                },
                1200: {
                  slidesPerView: 6,
                  spaceBetween: 25,
                  grid: {
                    rows: 2,
                  },
                },
              }}
              grid={{
                rows: 2,
              }}
              rewind={true}
              loopAddBlankSlides={false}
              autoplay={{
                stopOnLastSlide: true,
              }}
              pagination={{
                clickable: true,
              }}
              draggable={true}
              modules={[Grid, Pagination]}
              className="mySwiper">
              <div className="swiper-skeleton">
                {" "}
                {brands.map((data, i) =>
                  loading ? (
                    <Skeleton
                      variant="rectangular"
                      sx={{ bgcolor: "grey.800", margin: "30px" }}
                      width={220}
                      height={200}
                    />
                  ) : (
                    <SwiperSlide className="brands_swiper_el">
                      <a
                        className="brands_cars_links1"
                        key={data.id}
                        href={`cars/${data.id}model`}>
                        <div className="brands_cards">
                          <img
                            className="brands_swiper_picture"
                            src={`${API}/uploads/images/${data.image_src}`}
                            alt="This is the brands"
                          />
                          <h3 className="brand_cards_titile">{data.title}</h3>
                        </div>{" "}
                      </a>
                    </SwiperSlide>
                  )
                )}
              </div>
            </Swiper>
          </div>
          <div style={{ marginTop: "50px" }} className="brandsswiper_flex_responsive">
            <Swiper
              slidesPerView={2}
              spaceBetween={20}
              // breakpoints={{
              //   350: {
              //     slidesPerView: 2,
              //     spaceBetween: 40,
              //     grid: {
              //       rows: 2,
              //     },
              //   },
              //   700: {
              //     slidesPerView: 2,
              //     spaceBetween: 40,
              //   },
              //   900: {
              //     slidesPerView: 4,
              //     spaceBetween: 40,
              //   },
              //   1200: {
              //     slidesPerView: 6,
              //     spaceBetween: 25,
              //     grid: {
              //       rows: 2,
              //     },
              //   },
              // }}
              grid={{
                rows: 2,
              }}
              // rewind={true}
              // loopAddBlankSlides={false}
              // autoplay={{
              //   stopOnLastSlide: true,
              // }}
              pagination={{
                clickable: true,
              }}
              rewind={true}
              navigation={false}
              draggable={true}
              modules={[Grid, Pagination]}
              className="mySwiper responsive__swiper">
              <div className="swiper-skeleton">
                {" "}
                {brands.map((data, i) =>
                  loading ? (
                    <Skeleton
                      variant="rectangular"
                      sx={{ bgcolor: "grey.800", margin: "30px" }}
                      width={220}
                      height={200}
                    />
                  ) : (
                    <SwiperSlide className="brands_swiper_el">
                      <a
                        className="brands_cars_links1"
                        key={data.id}
                        href={`cars/${data.id}model`}>
                        <div className="brands_cards">
                          <img
                            className="brands_swiper_picture"
                            src={`${API}/uploads/images/${data.image_src}`}
                            alt="This is the brands"
                          />
                          <h3 className="brand_cards_titile">{data.title}</h3>
                        </div>{" "}
                      </a>
                    </SwiperSlide>
                  )
                )}
              </div>
            </Swiper>
          </div>
        </div>
      </div>
    </>
  );
}

export default BrandsSwiper;
