import rus from './ru.json'; 
import eng from './en.json';



const resources =  { 
     ru: {translation: rus}, 
     en: {translation: eng}, 
}

export default resources