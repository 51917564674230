import React, { useContext, useEffect, useState } from "react";
import "./Changecars.css";
import Supercars from "../../components/Supercars/Supercars";
import axios from "axios";
import API from "../../api";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

function Changecars() {
  const { t } = useTranslation();
  const currentLanguage = i18next.language;

  const [carData, setCarData] = useState([]);

  useEffect(() => {
    axios
      .get(`${API}/cars/category`)
      .then((res) => {
        setCarData(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <>
      <div className="changecars">
        {carData.map((item, i) => {
          return <Supercars key={i} cars={item.cars} carItems={item} />;
        })}
      </div>
    </>
  );
}

export default Changecars;
