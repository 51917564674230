import React from 'react'
import './FaqPage.css';
 
import Location from '../Location/Location';
import Accordion from '../../components/Accardion/Accardion';

export default function FaqPage() {
  return (
    <>
        <div className="faq">
            <div className="container"> 
                <h1 className='ser_subtitle' >FAQ</h1>
                <Accordion/>
            </div> 
            {/* <Location/> */}
        </div>
    </>
  )
}
