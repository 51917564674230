import React, { useEffect, useState } from "react";
import { Link, NavLink, useParams } from "react-router-dom";
import { MdWhatsapp } from "react-icons/md";
import { RiTelegramFill } from "react-icons/ri";
import axios from "axios";
import "./Carsfilter.css";
import API from "../../api";
// import Location from "../Location/Location";
import { useTranslation } from "react-i18next";
import { Skeleton } from "@mui/material";
import SettingsIcon from "../../assets/svg/settings.svg";

function Carsfilter() {
  const { id } = useParams();
  const [carsInfo, setCarsInfo] = useState([]);
  const [categories, setCategories] = useState([]);
  const [brands, setBrands] = useState([]);
  const [models, setModels] = useState([]);
  const [settings, setSettings] = useState(false);
  // function
  const [selectedInputs, setSelectedInputs] = useState([]);
  const [selectedInputsCategories, setSelectedInputsCategories] = useState([]);
  const [selectModel, setSelectModel] = useState("");
  const [cars, setCars] = useState([]);
  const [offer, setOffer] = useState([]);
  const [offerSlug, setOfferSlug] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadingTimeout = setTimeout(() => {
      setLoading(false);
    }, 2000);
    return () => clearTimeout(loadingTimeout);
  }, []);

  const offerData = [
    {
      slug: "three_days_price",
      id: 5000,
      title: "3 DAYS RENT = 5000 AED🔥 ALL INCLUSIVE ",
    },
    {
      slug: "three_days_price",
      id: 1300,
      title: "3 DAYS RENT = 1300 AED🔥 ()",
    },
    {
      slug: "three_days_price",
      id: 1800,
      title: "3 DAYS RENT = 1800 AED🔥",
    },
    {
      slug: "no_deposit",
      id: "0",
      title: "NO DEPOSIT",
    },
    {
      slug: "all_inclusive",
      id: "true",
      title: " 5000 AED🔥 ALL INCLUSIVE ",
    },
    {
      slug: "all_inclusive",
      id: "true",
      title: "2 DAYS RENT = 5000 AED🔥 ALL INCLUSIVE ",
    },
    {
      slug: "rent_ferrari",
      id: "true",
      title: "Rent Ferrari Dubai",
    },
    {
      slug: "all_inclusive",
      id: "true",
      title: "4 DAYS RENT = 5000 AED🔥 ALL INCLUSIVE ",
    },
  ];

  const handleSelectChange = (event) => {
    setSelectModel(event.target.value);
  };
  const [trigger, setTrigger] = useState(false); // Trigger for the second API call

  const handleCheckboxChangeCategories = (value) => {
    // Check if the value is already in the array
    if (selectedInputsCategories.includes(value)) {
      // If it is, remove it
      setSelectedInputsCategories(
        selectedInputsCategories.filter((item) => item !== value)
      );
    } else {
      // If it's not, add it
      setSelectedInputsCategories([...selectedInputsCategories, value]);
    }
  };
  const urlParamsCategory = selectedInputsCategories
    .map((id) => `category_id=${id}`)
    .join("&");

  const handleCheckboxChangeOffer = (slug, value) => {
    if (offer.includes(value) && offerSlug.includes(slug)) {
      setOffer(offer.filter((item) => item !== value));
      setOfferSlug(offerSlug.filter((item) => item !== slug));
    } else {
      setOffer([...offer, value]);
      setOfferSlug([...offerSlug, slug]);
    }
  };

  const queryString = offerSlug
    .map((key, index) => `${key}=${offer[index]}`)
    .join("&");

  const handleCheckboxChange = (value) => {
    if (selectedInputs.includes(value)) {
      setSelectedInputs(selectedInputs.filter((item) => item !== value));
    } else {
      setSelectedInputs([...selectedInputs, value]);
    }
  };
  
  const urlParams = selectedInputs.map((id) => `brand_id=${id}`).join("&");
  useEffect(() => {
    axios
      .get(
        `${API}/cars?limit=300&${
          id?.slice(-5) === "model"
            ? "brand_id"
            : id?.slice(-8) === "location"
            ? "location_id"
            : id?.slice(-4) === "city"
            ? "city_id"
            : id?.slice(-7) === "keyword" 
            ? "keyword"
            : "category_id"
        }=${
          id === undefined
            ? ""
            : id?.slice(-5) === "model"
            ? id?.slice(0, -5)
            : id?.slice(-8) === "location"
            ? id?.slice(0, -8)
            : id?.slice(-4) === "city"
            ? id?.slice(0, -4) 
            :  id?.slice(-7)  === "keyword"
            ?  id?.slice(0,-7)
            : id
        }`
      )
      .then((res) => {
        setCarsInfo(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [selectModel, urlParamsCategory, urlParams, id]);

  useEffect(() => {
    axios
      .get(`${API}/categories`)
      .then((res) => {
        setCategories(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    axios
      .get(`${API}/brands`)
      .then((res) => {
        setBrands(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    axios
      .get(`${API}/models?${urlParams}`)
      .then((res) => {
        setModels(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [urlParams]);

  const updateTrigger = () => {
    setTrigger((prevTrigger) => !prevTrigger);
  };

  const handleSubmitCarsFilter = (event) => {
    event.preventDefault();
    axios
      .get(
        `${API}/cars?${
          id?.slice(-5) === "model"
            ? "brand_id"
            : id?.slice(-8) === "location"
            ? "location_id"
            : id?.slice(-4) === "city"
            ? "city_id"
            : id?.slice(-7)  === "keyword"
            ? "keyword"
            : "category_id"
        }=${
          id === undefined
            ? ""
            : id?.slice(-5) === "model"
            ? id?.slice(0, -5)
            : id?.slice(-8) === "location"
            ? id?.slice(0, -8)
            : id?.slice(-4) === "city"
            ? id?.slice(0, -4)
            : id?.slice(-7)  === "keyword"
            ? ""
            : id
        }&${urlParams}&${urlParamsCategory}&model_id=${selectModel}&${queryString}`
      )
      .then((response) => {
        setCars(response.data.data);
        console.log("Response:", response.data);
        setSettings(false)

      })
      .catch((error) => {
        console.error("Error submitting form:", error);
        // Handle errors
      });
  };

  const { t } = useTranslation();

  function closeFilterModal(close) {
    setSettings(close)
  }
  const handleClick = () => {
    setSettings(true);
  };
console.log(settings,"settinggss")
  return (
    <>
      {/* <Settings settingsModal={settings} setSettings={setSettings} closeFilter={closeFilterModal} /> */}
      <>
      <div className="settings-open">
        {settings === false ? (
          <button onClick={handleClick} className="settings-button">
            <img
              className="settings-icon"
              src={SettingsIcon}
              alt="SettingsIcon"
            />
          </button>
        ) : (
          ""
        )}
      </div>
      {settings === true ? (
        <button onClick={() => setSettings(false)} className="settings-close">
          <svg
            class="filter__close"
            width="21"
            height="16"
            viewBox="0 0 21 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M20.5 7.54688H2M2 7.54688L9 0.546875M2 7.54688L9 14.5469"
              stroke="white"
              stroke-width="1.5"></path>
            <path
              d="M20.5 7.54688H2M2 7.54688L9 0.546875M2 7.54688L9 14.5469"
              stroke="white"
              stroke-width="1.5"></path>
            <path
              d="M20.5 7.54688H2M2 7.54688L9 0.546875M2 7.54688L9 14.5469"
              stroke="white"
              stroke-width="1.5"></path>
          </svg>
        </button>
      ) : (
        ""
      )}
      {settings === true ? (
        <div className="filter_fath_modal">
          <div className="filter_container_modal">
            <h3 className="filter_by">Filter By</h3>
            <form className="filter_part_car" onSubmit={(event) => handleSubmitCarsFilter(event)}>
              <div className="filter_to_offer">
                <h4 className="part_title">Offers</h4>
                {offerData?.map((item) => (
                  <label
                    className="filter_labels"
                    checked={offer.includes(item?.id)}
                    onChange={() =>
                      handleCheckboxChangeOffer(item?.slug, item?.id)
                    }>
                    <input className="filter_inputs" type="checkbox" />
                    {item?.title}
                  </label>
                ))}
              </div>

              {/* Car Type  */}

              <div className="filter_to_offer">
                <h4 className="part_title">Car type</h4>
                {categories?.map((item, i) => (
                  <label
                    key={i}
                    className="filter_labels"
                    checked={selectedInputsCategories?.includes(item?.id)}
                    onChange={() => handleCheckboxChangeCategories(item?.id)}>
                    <input className="filter_inputs" type="checkbox" />
                    {item?.name_en}
                  </label>
                ))}
              </div>

              {/* Brand filter  */}

              <div className="filter_to_offer">
                <h4 className="part_title">Brand</h4>
                {brands?.map((item, index) => (
                  <label
                    key={index}
                    className="filter_labels"
                    onClick={updateTrigger}
                    checked={selectedInputs.includes(item?.id)}
                    onChange={() => handleCheckboxChange(item?.id)}>
                    <input className="filter_inputs" type="checkbox" />
                    {item?.title}
                  </label>
                ))}
              </div>

              <div className="filter_to_offer">
                <h4 className="part_title">Model</h4>
                <select
                  className="filter_to_select"
                  value={selectModel}
                  onChange={handleSelectChange}>
                  {models?.map((item) => (
                    <option className="select_option_filter" value={item?.id}>
                      {item?.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="filter_buttons_flex">
                <button
                  onClick={() => window.location.reload()}
                  className="filter_buttons reseet_button">
                  Reset
                </button>
                <button type="submit" className="filter_buttons apply_button">
                  Apply filter
                </button>
              </div>
            </form>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
      <div className="carfilter">
        <div className="carfilter_flex">
          <div className="filter_fath">
            <div className="filter_container">
              <h3 className="filter_by">Filter By</h3>
              <form
                className="filter_part_car"
                onSubmit={handleSubmitCarsFilter}
              >
                <div className="filter_to_offer">
                  <h4 className="part_title">Offers</h4>
                  {offerData?.map((item) => (
                    <label
                      className="filter_labels"
                      checked={offer.includes(item?.id)}
                      onChange={() =>
                        handleCheckboxChangeOffer(item?.slug, item?.id)
                      }
                    >
                      <input className="filter_inputs" type="checkbox" />
                      {item?.title}
                    </label>
                  ))}
                </div>

                {/* Car Type  */}

                <div className="filter_to_offer">
                  <h4 className="part_title">Car type</h4>
                  {categories?.map((item, i) => (
                    <label
                      key={i}
                      className="filter_labels"
                      checked={selectedInputsCategories.includes(item?.id)}
                      onChange={() => handleCheckboxChangeCategories(item?.id)}
                    >
                      <input className="filter_inputs" type="checkbox" />
                      {item?.name_en}
                    </label>
                  ))}
                </div>

                {/* Brand filter  */}

                <div className="filter_to_offer">
                  <h4 className="part_title">Brand</h4>
                  {brands?.map((item, index) => (
                    <label
                      key={index}
                      className="filter_labels"
                      onClick={updateTrigger}
                      checked={selectedInputs.includes(item?.id)}
                      onChange={() => handleCheckboxChange(item?.id)}
                    >
                      <input className="filter_inputs" type="checkbox" />
                      {item?.title}
                    </label>
                  ))}
                </div>

                <div className="filter_to_offer">
                  <h4 className="part_title">Model</h4>
                  <select
                    className="filter_to_select"
                    value={selectModel}
                    onChange={handleSelectChange}
                  >
                    {models?.map((item) => (
                      <option className="select_option_filter" value={item?.id}>
                        {item?.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="filter_buttons_flex">
                  <button
                    onClick={() => window.location.reload()}
                    className="filter_buttons reseet_button"
                  >
                    Reset
                  </button>
                  <button type="submit" className="filter_buttons apply_button">
                    Apply filter
                  </button>
                </div>
              </form>
            </div>
          </div>

          {settings === false ? (
            <div className="all_cars_in_filter_page">
              <div className="all_cars_container">
                <NavLink to="/cars" className="ser_page_link">
                  {t("contactTitle1")} /{" "}
                  <span className="ser_page_link">{t("lamboText")}</span>{" "}
                </NavLink>

                <h3 className="all_cars_title">{}</h3>
                <div className="all_cars_flex">
                  {(cars?.length > 0 ? cars : carsInfo).map((item, i) => (
                    loading ? (
                      <Skeleton
                        variant="rectangular"
                        sx={{ bgcolor: "grey.800" }}
                        width={300}
                        height={430}
                      />
                    ) : (<div
                      onClick={() => window.scrollTo({ top: 0 })}
                      className="all_cars_cards"
                      key={i}>
                      <Link to={`/carsinfo/${item?.id}`}>
                      <div className="all_card_picture">
                        <img
                          className="cars_swiper_picture"
                          src={`${API}/uploads/images/${
                            item.car_images.find((img) => img.is_main)?.image.src ||
                            item.car_images[0]?.image.src
                          }`}
                          alt=""
                        />
                      </div>
                      {item?.inclusive ? (
                        <span className="color_bonus_price">
                          5000 AED 🔥 ALL INCLUSIVE
                        </span>
                      ) : (
                        ""
                      )}

                      <h3 className="slide_car_title cars_card_title">
                        {item.brand.title} {item.model.name}
                      </h3>
                      <h4 className="slide_car_price_aed">
                        AED {item.price_in_aed}
                        <span className="slide_car_price_usd">
                          / $ {item.price_in_usd}
                        </span>
                      </h4>
                      <p className="slide_car_price_sale">
                        {item.price_in_aed_sale
                          ? "AED " + item.price_in_aed_sale + "/"
                          : ""}{" "}
                        {item.price_in_usd_sale
                          ? "$ " + item.price_in_usd_sale
                          : ""}
                      </p>
                      <p className="renta_type">per day</p>
                      </Link>

                      <div className="all_cars_card_btn_flex">
                        <a
                          className="all_cars_card_btns whatsApp_btn"
                          href="https://wa.me/971527030189"
                          target="_blank"
                        >
                          <MdWhatsapp />
                          WhatsApp
                        </a>

                        <a
                          className="all_cars_card_btns telegram_btn"
                          href="https://t.me/+971527030189"
                          target="_blank"
                        >
                          <RiTelegramFill />
                          Telegram
                        </a>
                      </div>
                    </div>)
                  ))}
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
}

export default Carsfilter;
