import React from "react";
import "./Lambopart.css";
import { Link } from "react-router-dom";
import {useTranslation} from "react-i18next"

function Lambopart() {

  const { t} = useTranslation()

  return (
    <>
      <div className="lambopart">
        <div className="lambopart_flex">
          <div className="lambopart_picture_cont"></div>
          <div className="lambo_part_info_cont">
            <h2 className="lambo_part_info_title">{t('lamboTitle')}</h2>
            <p className="lambo_part_info_mini_text">
              {t('lamboText')}
            </p>
            <Link onClick={() =>
                  window.scrollTo({ top: 0 })
              } to={'/cars'} className="more_link_lc">
              <span className="luxury_comp_link_flex">
                {t('seeAll')}
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13 10L21 16.5L13 23L17.5714 16.5L13 10Z"
                    fill="white"
                  ></path>
                  <circle cx="16" cy="16" r="15.5" stroke="white"></circle>
                </svg>
              </span>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default Lambopart;
