import React from 'react'
import LuxurydescComp from '../../components/LuxurydescComp/LuxurydescComp';

function Luxurydesc() {
  return (
    <>
      <LuxurydescComp />
    </>
  )
}

export default Luxurydesc;