import React, { useContext } from 'react';
import "./FollowModal.css";
import { MdOutlineClose } from "react-icons/md";
import { Context } from '../Contexs/ContexProvider';

function FollowModal({ picture, open }) {

    const { closeFunc } = useContext(Context);

  return (
    <>
        <div className={`${open ? "openModal" : null} followModal`} onClick={() => closeFunc()}>
        <MdOutlineClose className="followModal_close" onClick={() => closeFunc()} />
            <div className="followmodal_content">
                <img className='followmodal_content_image' src={picture} alt="" />
            </div>
        </div>
    </>
  )
}

export default FollowModal;