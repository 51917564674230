import React from 'react'
import SportCarCard from '../../components/Sport_Car_Card/SportCarCard';
// import SerImg1 from "../../assets/images/sercardimg1.jpg";
// import SerImg2 from "../../assets/images/sercardiimg2.jpg";
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';



export default function ServiceSportCar() {
  const {t} = useTranslation() 


    return (
    <>
      <div className="service_sportcar">
        <div className="container">
          <Link to="/" className="ser_page_link" >{t('contactTitle1')}/ <Link to="service" className='ser_page_link' >{t('serviceSmallTitle')}</Link>/ Sports Car Rental Dubai Style Tour in Dubai </Link>
          <h1 className='ser_subtitle' >Sports Car Rental Dubai Style Tour in Dubai</h1>
          <div className="service_sportcar_box">
            <SportCarCard />
          </div>
        </div>
      </div>
    </>
  )
}
