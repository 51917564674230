import React, { useEffect, useState } from "react";
import './Location.css';
import API from "../../api";
import axios from "axios";

import { NavLink } from 'react-router-dom';
import { useTranslation } from "react-i18next";

export default function Location() {
  const { t } = useTranslation()

  const [locations, setLocation] = useState([]);
  const [cities, setCities] = useState([]);


  useEffect(() => {
    try {
      axios.get(`${API}/locations`).then((res) => {
        setLocation(res.data.data);
      });

      axios.get(`${API}/cities`).then((res) => {
        setCities(res.data.data);
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

 
  return (
    <>
      <div className="location">
        <div className="container">
          <div className="location_wrapper">
            <div className="location_box">
              <h2 className='location_title'>{t('location')}</h2>
              <div className='location_link_box'>
                <ul className="location_link_box_item">
                
                  {
                    locations.map((item)=>{
                      return (
                        <li key={item.id}><NavLink onClick={() =>
                          window.scrollTo({ top: 0 })
                      } className="location_link" to={`cars/${item?.id}location`} >{item.name}</NavLink></li>
                      )
                    })
                  }
                 
                   
                </ul>
            
              </div>
            </div>
            <div className="city_box">
              <h2 className='location_title'>{t('city')}</h2>
              <div className='location_link_box'>
                <ul className="location_link_box_item">
                {
                    cities.map((item)=>{
                      return (
                        <li key={item.id}><NavLink onClick={() =>
                          window.scrollTo({ top: 0 })}   to={`cars/${item?.id}city`}  className="location_link">{item.name}</NavLink></li>
                      )
                    })
                  }
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
