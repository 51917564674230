import React, { useEffect, useState } from "react";
import "./Models.css";
import axios from "axios";
import { Link } from "react-router-dom";
import BrandsSwiper from "../../components/Layout/BrandsSwiper/BrandsSwiper";
import API from "../../api";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { Skeleton } from "@mui/material";

function Models() {
  const [models, setModels] = useState([]);
  const { t } = useTranslation();
  const currentLanguage = i18next.language;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios.get("https://car.uzloyal.uz/api/categories")
      .then((res) => {
        setModels(res?.data?.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    const loadingTimeout = setTimeout(() => {
      setLoading(false);
    }, 2000);
    return () => clearTimeout(loadingTimeout);
  }, []);

  return (
    <>
      <div className="models">
        <div className="container">
          <div className="models_flex">
            {models.map((data) =>
              loading ? (
                <>
                  <Skeleton
                    variant="rectangular"
                    sx={{ bgcolor: "grey.800" }}
                    width={360}
                    height={160}
                  />
                </>
              ) : (
                <Link
                  className="model_cards_link"
                  onClick={() => window.scrollTo({ top: 0 })}
                  key={data.id}
                  to={`cars/${data.id}`}
                >
                  <div className="model_cards">
                      <h2 className="model_cards_title">
                      {data[`name_${currentLanguage}`] || data.name_en}
                    </h2>
                       <img
                        className="model_cards_img"
                        src={`${API}/uploads/images/${data.image_src}`}
                        alt="This is the cars model"
                        width={360}
                      />
                  </div>
                </Link>
              )
            )}
          </div>
        </div>
        <BrandsSwiper />
      </div>
    </>
  );
}

export default Models;
