import React from 'react'
import './SportCarCard.css';

import DesertCar from '../../assets/images/desrtcar.jpg';
import Checkbox from '../../assets/images/check-box.png';
// import emptyCh from '../../assets/images/empty-checkbox.png';
import SerImg1 from "../../assets/images/sercardimg1.jpg";
import SerImg2 from "../../assets/images/sercardiimg2.jpg";
import { useParams } from 'react-router-dom';

export default function SportCarCard() {

  // const SportCarData = [
  //   {
  //     img: DesertCar,
  //     title: "BASIC",
  //     number: "1000",
  //     TrueCheckbox: Checkbox,
  //     FalseCheckbox: emptyCh

  //   },
  //   {
  //     img: DesertCar,
  //     title: "DESERT TOUR 2 HOURS",
  //     number: "2000",
  //     TrueCheckbox: Checkbox,
  //     FalseCheckbox: emptyCh
  //   },
  //   {
  //     img: DesertCar,
  //     title: "DESERT TOUR 3 HOURS",
  //     number: "3000",
  //     TrueCheckbox: Checkbox,
  //     FalseCheckbox: emptyCh
  //   }
  // ]

  const servicePage = [
    {
      img: SerImg1,
      text: "Sports Car Rental Dubai Style Tour in Dubai",
      title: "Experience the thrill of a dune buggy tour in Dubai with us. We offer free hotel pick-up & drop-off service. Book now! ",
      link: "sport_car_rent",
      desc: "Dune buggies in Dubai Book your ride on the most exciting adventure activity to hit the desert and later pamper your self to a traditional Arabian hospitality.",
      price: "1000",
      count: "per person",
      data: [
        {
          img: SerImg2,
          text: "BASIC",
          title: "1212Professional car photoshoot  as an additional type of service at Auto Zoom Car Rental",
          link: "photoshoot-with-luxury-car-in-dubai",
          desc: "Dune buggies in Dubai Book your ride on the most exciting adventure activity to hit the desert and later pamper your self to a traditional Arabian hospitality.",
          price: "1000",
          count: "one person"
        },
        {
          img: SerImg2,
          text: "DESERT TOUR 2 HOURS                                                    " ,
          title: "",
          link: "photoshoot-with-luxury-car-in-dubai",
          desc: "1asasa2334343434Dune buggies in Dubai Book your ride on the most exciting adventure activity to hit the desert and later pamper your self to a traditional Arabian hospitality.",
          price: "2000",
          count: "two person"
        },
        {
          img: SerImg2,
          text: "12212Photoshoot with luxury car rental Dubai",
          title: "1212Professional car photoshoot  as an additional type of service at Auto Zoom Car Rental",
          link: "photoshoot-with-luxury-car-in-dubai",
          desc: "121212334343434Dune buggies in Dubai Book your ride on the most exciting adventure activity to hit the desert and later pamper your self to a traditional Arabian hospitality.",
          price: "3000",
          count: "two person"
        }
      ]
    },
    {
      img: SerImg2,
      text: "Photoshoot with luxury car rental Dubai",
      title: "Professional car photoshoot  as an additional type of service at Auto Zoom Car Rental",
      link: "photoshoot-with-luxury-car-in-dubai",
      desc: "12334343434Dune buggies in Dubai Book your ride on the most exciting adventure activity to hit the desert and later pamper your self to a traditional Arabian hospitality.",
      price: "7775",
      count: "two person",
      data: [
        {
          img: SerImg2,
          text: "aassasas with luxury car rental Dubai",
          title: "1212Professional car photoshoot  as an additional type of service at Auto Zoom Car Rental",
          link: "photoshoot-with-luxury-car-in-dubai",
          desc: "121212334343434Dune buggies in Dubai Book your ride on the most exciting adventure activity to hit the desert and later pamper your self to a traditional Arabian hospitality.",
          price: "232323",
          count: "two person"
        },
        {
          img: SerImg2,
          text: "23232323 hotoshoot with luxury car rental Dubai",
          title: "qwqwProfessional car photoshoot  as an additional type of service at Auto Zoom Car Rental",
          link: "photoshoot-with-luxury-car-in-dubai",
          desc: "1asasa2334343434Dune buggies in Dubai Book your ride on the most exciting adventure activity to hit the desert and later pamper your self to a traditional Arabian hospitality.",
          price: "232332",
          count: "tasaswo person"
        }
      ]
    },
    {
      img: SerImg2,
      text: "Photoshoot with luxury car rental Dubai",
      title: "Professional car photoshoot  as an additional type of service at Auto Zoom Car Rental",
      link: "photoshoot-with-luxury-car-in-dubai",
      desc: "12334343434Dune buggies in Dubai Book your ride on the most exciting adventure activity to hit the desert and later pamper your self to a traditional Arabian hospitality.",
      price: "7775",
      count: "two person"
    }
  ]

  const { id } = useParams()

  const productAbout = servicePage?.find((item) => item?.link === id)
  const {  data} = productAbout;
    return (
    <>
    {data?.length>0 ? data?.map((item,key)=>(
     <div key={key} className="sport_car">
     <img className='sport_car_img' src={DesertCar} alt="Car" />
     <div className="sport_car_box">
       <h1 className='sport_car_title'>BASIC</h1>
       <div className='sport_car_price'>
         <span>
           {item?.price}
         </span>
         <p>{item?.count}</p>
       </div>
       <p className='sport_car_text'>{item?.desc}</p>
       <h3 className='sport_car_subtext'>Package Inclusions:</h3>
       <ul className='sport_card_chekbox_list'>
         <li className='sport_card_chekbox_item'>
           <img className='sport_car_img_checkbox' src={Checkbox} alt="" />
           <p className='sport_car_checkbox_text'>Premium Transfer to camp</p>
         </li>
         <li className='sport_card_chekbox_item'>
           <img className='sport_car_img_checkbox' src={Checkbox} alt="" />
           <p className='sport_car_checkbox_text'>Dune Buggy riding 1.5 hours</p>
         </li>
         <li className='sport_card_chekbox_item'>
           <img className='sport_car_img_checkbox' src={Checkbox} alt="" />
           <p className='sport_car_checkbox_text'>Cuadro 15 min</p>
         </li>
         <li className='sport_card_chekbox_item'>
           <img className='sport_car_img_checkbox' src={Checkbox} alt="" />
           <p className='sport_car_checkbox_text'>Falcone shoting</p>
         </li>
         <li className='sport_card_chekbox_item'>
           <img className='sport_car_img_checkbox' src={Checkbox} alt="" />
           <p className='sport_car_checkbox_text'>Camel</p>
         </li>
         <li className='sport_card_chekbox_item'>
           <img className='sport_car_img_checkbox' src={Checkbox} alt="" />
           <p className='sport_car_checkbox_text'>VIP room</p>
         </li>
         <li className='sport_card_chekbox_item'>
           <img className='sport_car_img_checkbox' src={Checkbox} alt="" />
           <p className='sport_car_checkbox_text'>Fruits, Drinks, BBQ</p>
         </li>
       </ul>
       <a href='tel:+971527030189'><button href='tel:971527030189' className='sport_car_btn'>BOOK NOW</button></a>
       
     </div>
   </div>
    )) : "hech narsa yo'q"}

    </>
  )
}
