import React from "react";
import API from "../../api";
import "./Brand.css";
import { Link } from "react-router-dom";

function BrandData({propsI, hoverProps, closeNavporps}) {
  const { title, image_src, id } = propsI;
  const [brand, model] = title.split(" ");

  return (
    <Link to={`/cars/${id}model`} onClick={() => {
      hoverProps(false);
      closeNavporps(false);
    }
    }>
      <li className="brand__item" >
      <img
        className="brand__image"
        src={`${API}/uploads/images/${image_src}`}
        alt={title}
      />
      <h4 className="brand__name">
        <span className="bold">Rent</span>
        <span className="name">{title}</span>
        <span className="bold">Dubai</span>
      </h4>
    </li>
    </Link>
  );
}

export default BrandData;
