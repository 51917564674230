import React from "react";
import "./Supercars.css";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";

import { Link } from "react-router-dom";
import API from "../../api";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

function Supercars({ cars, carItems }) {
  const { t } = useTranslation();
  const currentLanguage = i18next.language;
  // console.log(cars);
  return (
    <>
      <div className="container">
        <div className="link_and_title_flex">
          <h2 className="supercars_title">
            {carItems[`name_${currentLanguage}`].toUpperCase()}
            {currentLanguage === "en" && " RENTAL DUBAI"}
          </h2>
          <Link onClick={() =>
                  window.scrollTo({ top: 0 })
              } className="more_link" to={`/cars/${carItems?.id}`}>
            SEE ALL
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M13 10L21 16.5L13 23L17.5714 16.5L13 10Z"
                fill="white"></path>
              <circle cx="16" cy="16" r="15.5" stroke="white"></circle>
            </svg>
          </Link>
        </div>
        <Swiper
          // slidesPerView={3}
          // spaceBetween={70}
          draggable={true}
          breakpoints={{
            600: {
              slidesPerView: 1,
              spaceBetween: 40,
            },
            700: {
              slidesPerView: 2,
              spaceBetween: 40,
            },
            900: {
              slidesPerView: 3,
              spaceBetween: 40,
            },
            1200: {
              slidesPerView: 3,
              spaceBetween: 70,
            },
          }}
          className="mySwiper">
          {cars.map((data, i) => {
            return (
              <SwiperSlide className="slide_el">
                <Link
                  className="slide_element_link"
                  onClick={() => window.scrollTo({ top: 0 })}
                  key={i}
                  to={`carsinfo/${data.id}`}>
                  <div className="slide_flex">
                    <div className="slide_picture_div">
                      <img
                        className="cars_swiper_picture"
                        src={`${API}/uploads/images/${
                          data.car_images.find((img) => img.is_main)?.image.src ||
                          data.car_images[0]?.image.src
                        }`}
                        alt="rent car"
                      />
                    </div>
                    {data?.inclusive ? (
                      <span className="color_bonus_price">
                        5000 AED 🔥 ALL INCLUSIVE
                      </span>
                    ) : (
                      ""
                    )}
                    <h3 className="slide_car_title">
                      {data.brand.title} {data.model.name}
                    </h3>
                    <h4 className="slide_car_price_aed">
                      AED {data.price_in_aed}
                      <span className="slide_car_price_usd">
                        {" "}
                        / $ {data.price_in_usd}
                      </span>{" "}
                    </h4>
                    <p className="slide_car_price_sale">
                      {data.price_in_aed_sale
                        ? "AED " + data.price_in_aed_sale + "/"
                        : ""}{" "}
                      {data.price_in_usd_sale
                        ? "$ " + data.price_in_usd_sale
                        : ""}
                    </p>
                    <p className="renta_type">per day</p>
                  </div>
                </Link>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </>
  );
}

export default Supercars;
