import { Route, Routes } from "react-router-dom";
import Layouts from "./components/Layout/Layouts";
import HomePageCollector from "./HomePageCollector";
import SevicePage from "./pages/Service/SevicePage";
import Carsfilter from "./pages/Carsfilter/Carsfilter";
import ServiceAboutPage from "./pages/Service/ServiceSportCar";
import FaqPage from "./pages/FaqPage/FaqPage";
import AboutUs from "./pages/AboutUs/AboutUs";
import Ourteam from "./pages/OurTeam/Ourteam";
import Contact from "./pages/Contact/Contact";
import Blog from "./pages/Blog/Blog";
import BlogInfo from "./pages/Blog/BlogInfo";
import Terms from "./pages/Terms/Terms";
import Carinfo from "./pages/Carinfo/Carinfo";
import { useEffect, useState } from "react";
import Loader from "./components/Loader/Loader";


function App() {

  const [loading, setLoading] = useState(true);

  useEffect(() => { 
    const loadingTimeout = setTimeout(() => {
      setLoading(false);
    }, 3000);
 
    // Clear the timeout if the component unmounts before the 3 seconds
    return () => clearTimeout(loadingTimeout);
  }, []); 

  return (
    <>
      {loading && <Loader />}
      <Layouts>
        <Routes>
            <Route path="/" element={<HomePageCollector/>} />
            <Route path="/service" element={<SevicePage/>} />
            <Route path="/cars/:id" element={<Carsfilter/>} />
            <Route path="/cars/" element={<Carsfilter/>} />
            <Route path="/faq" element={<FaqPage/>} />
            <Route path="/service/:id" element={<ServiceAboutPage />} />
            <Route path="/about_us" element={<AboutUs/>} />
            <Route path="/our_team" element={<HomePageCollector/>} />
            <Route path="/contact" element={<Contact/>} />
            <Route path="/blog" element={<Blog/>} />
            <Route path="/blog_info/:id" element={<BlogInfo />} />
            <Route path="/terms_and_conditions" element={<Terms />} />
            <Route path="/carsinfo/:id" element={<Carinfo />} />
        </Routes>
      </Layouts>
    </>);
}

export default App;
