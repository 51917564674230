import React from 'react'
import './ServiceCard.css';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';



import SerCardLink from "../../assets/svg/cardLink.svg";



export default function ServiceCard({img, text, title, link}) {
  const {t} = useTranslation() 
   return (
    <div className="serviceCard">
        <img className='ser_card_img' src={img} alt="IMG" />
        <h1 className="ser_card_text" > {text}</h1>
        <p className='ser_card_desc'>{title}</p>
        <NavLink onClick={() =>
                  window.scrollTo({ top: 0 })
              } to={`${link}`} className="ser_card_link" >
            <p>{t('learnMore')}</p>
            <img  src={SerCardLink} alt="+" />
            
        </NavLink>
    </div>
  )
}
