import React, { createContext, useState } from "react";

export const Context = createContext({});

function ContexProvider({ children }) {

    const [handleModal, setHandleModal] = useState(false);
 
  let salom = 1;

 
   function closeFunc() {
        setHandleModal(false)
   }


  return (
    <>
      <Context.Provider value={{ closeFunc, setHandleModal, handleModal }}>{children}</Context.Provider>
    </>
  );
}

export default ContexProvider;
