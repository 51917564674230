import React from "react";
import { BrowserRouter } from "react-router-dom";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import ContexProvider from "./components/Contexs/ContexProvider";
import i18n from './i18';
import { I18nextProvider } from 'react-i18next';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <I18nextProvider i18n={i18n}>
   <BrowserRouter>
    <ContexProvider>
      <App />
    </ContexProvider>
  </BrowserRouter>
  </I18nextProvider>
 
);
