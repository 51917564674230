import React from 'react'
import './ServicePage.css';
import ServiceCard from '../../components/ServiceCard/ServiceCard';
import Location from '../Location/Location';
import { Link } from 'react-router-dom';
import SerImg1 from "../../assets/images/sercardimg1.jpg";
import SerImg2 from "../../assets/images/sercardiimg2.jpg";
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';




export default function SevicePage() {
  const {t} = useTranslation() 

  const servicePage = [
    {
      img: SerImg1,
      text: "Sports Car Rental Dubai Style Tour in Dubai",
      title: "Experience the thrill of a dune buggy tour in Dubai with us. We offer free hotel pick-up & drop-off service. Book now! ",
      link: "sport_car_rent"
    },
    {
      img: SerImg2,
      text: "Photoshoot with luxury car rental Dubai",
      title: "Professional car photoshoot  as an additional type of service at Auto Zoom Car Rental",
      link: "photoshoot-with-luxury-car-in-dubai"
    },
  ]

  return (
    <>
      <div className="service">
        <div className="container"> 
            <h1 className='ser_subtitle_home' >{t('services')}</h1>
            <div className="ser_page_card_box">
              {
                servicePage.map((item, i)=>
                <ServiceCard  key={i} img={item?.img} text={item?.text}
                title={item?.title}  link={`service/${item?.link}`}  />
                )
              }
            </div>
        </div> 
      </div>
    </>
  )
}
