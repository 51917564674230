import React from "react";
import "./Home.css";
import SwiperC from "../../components/Layout/Swiper/SwiperC";
import { useTranslation } from "react-i18next";


function Home() {
  const { t, i18n } = useTranslation();

  return (
    <>
      <div className="home">
        <div className="container">
          <div className="home_flex">
            <h1 className="home_title">{t('mainTitle')}</h1>
            <p className="home_text">
              {t('mainText')}
            </p>

            <a className="home_cars_link" href="/">
              <span className="home_cars_lflex">
                {t('catalogInvitation')}
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13 10L21 16.5L13 23L17.5714 16.5L13 10Z"
                    fill="white"
                  ></path>
                  <circle cx="16" cy="16" r="15.5" stroke="white"></circle>
                </svg>
              </span>
            </a>
          </div>
        </div>
        <SwiperC />
      </div>
    </>
  );
}

export default Home;
