import React, { useEffect, useState } from "react";
import Navbar from "./Navbar/Navbar";
import Footer from "./Footer/Footer";
import Loader from "../Loader/Loader";

export default function Layouts({ children }) { 
  return(<>
      <Navbar />
      {children}
      <Footer />
    </> )
}
