import React from 'react'
import './AboutUs.css';
import { NavLink } from 'react-router-dom';
import Ourteam from '../OurTeam/Ourteam';
import { useTranslation } from 'react-i18next';


export default function AboutUs() {
    const { t} = useTranslation()
    return (
        <>
            <div className="about">
                <div className="container about_container">
                    <div>
                        <NavLink to="/" className="ser_page_link">{t('contactTitle1')} / <span>{t('aboutUs2')}</span></NavLink>
                        <h1 className='ser_subtitle'>{t('aboutUs')}</h1>
                    </div>
                    <div className="about_texts">
                        <h2 className="about_big_title">
                            {t('aboutTextTitle')}
                        </h2>
                        <p className='about_bigText'>
                            {t('aboutTextSubtitle1')}
                        </p>

                        <h3 className="about_title_ques">
                            {t('whyAboutTitle')}
                        </h3>

                        <div className='about_div_conm'>
                        <h4 className="about_in_titles">{t('whyAboutInTitle1')}</h4>
                        <p className="about_in_text">{t('whyAboutText1')}</p>
                        </div>

                        <div className='about_div_conm'>
                        <h4 className="about_in_titles">{t('whyAboutInTitle2')}</h4>
                        <p className="about_in_text">{t('whyAboutText2')}</p>
                        </div>

                       <div className='about_div_conm'>
                       <h4 className="about_in_titles">{t('whyAboutInTitle3')}</h4>
                        <p className="about_in_text">{t('whyAboutText3')}</p>
                       </div>

                       <div className='about_div_conm'>
                       <h4 className="about_in_titles">{t('whyAboutInTitle4')}</h4>
                        <p className="about_in_text">{t('whyAboutText4')}</p>
                       </div>

                        <h3 className="about_mission_title deffer">
                            {t('missionAboutTitle')}
                        </h3>
                        <p className='about_misson_text'>
                            {t('missionAboutText')}
                        </p>


                        <h3 className="about_mission_title">
                            {t('contactAboutTitle')}
                        </h3>
                        <p className='about_misson_text'>
                            {t('contactAboutText')}
                        </p>
                    </div>
                </div>
            </div>
            {/* <div className="about_row">
                <div className="container">
                    <div className="about_row_text_box">
                        <h3 className='about_row_text'>
                            {t('about2info')}
                        </h3>
                       
                    </div>
                </div>
            </div> */}
            {/* <div className="about_aside">
                <div className="container">
                        <p className='about_row_text'>
                        Rustem Markhiev, the owner of Autozoom says: "It’s an exhilarating and dynamic industry to be a part of. At Autozoom Rent A Car, our top priority is to provide safe and luxurious transport solutions. We strive to continue to give great customer service and ensure high levels of customer satisfaction, exceeding their expectations. We are proud to set a benchmark in the industry, where we are the people's choice, residents, celebrities and tourists alike. In the world of automobile rental, small improvements and constantly adapting to the ever-changing market has helped us to create the well-known brand that we are.”
                        </p>
                        <p className='about_row_text'>
                            From luxury to convenience, they provide it all. Autozoom rent a car assures an easy payment procedure, a wide range of cars to choose from and quality service.
                        </p>
                        <p className='about_row_text'>
                            If you are planning to rent exotic or luxury cars in Dubai, choose in our website or contact us +971 4 383 96 96.
                        </p>
                        <p className='about_row_text'>
                        We advise you to rent ferrari dubai and to rent lamborghini dubai style for the best supercar rental dubai experience..
                        </p>
                </div>
            </div> */}
            {/* <Ourteam/> */}

        </>
    )
}
