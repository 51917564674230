import React from "react";
import "./LuxurydescComp.css";
import { Link } from "react-router-dom";
import {useTranslation} from  "react-i18next"

function LuxurydescComp() {

  const {t}  = useTranslation()


  return (
    <>
      <div className="luxury_comp">
        <div className="container">
          <div className="luxury_comp_flex">
            <h2 className="luxury_comp_title">{t('rentTitle')}</h2>
            <p className="luxury_comp_text">
              {t('rentText')}
            </p>
            <h2 className="luxury_comp_title">{t('rentTitle2')}</h2>
            <p className="luxury_comp_text">
              {t('rentText2')}
            </p>
            {/* <Link className="more_link_lc">
              <span className="luxury_comp_link_flex">
                {t('rentLearnMore')}
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13 10L21 16.5L13 23L17.5714 16.5L13 10Z"
                    fill="white"
                  ></path>
                  <circle cx="16" cy="16" r="15.5" stroke="white"></circle>
                </svg>
              </span>
            </Link> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default LuxurydescComp;
