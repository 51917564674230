import React, { useState } from 'react';
import './Accardion.css';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';


const Accordion = () => {
  const [openIndex, setOpenIndex] = useState(null);
  const { t } = useTranslation()
  const currentLanguage = i18next.language


  const AccardionDate = [
    {
      btn_en: "What is the minimum age requirements to rent a car in Dubai ?",
      btn_ru:"Какой минимальный возраст для аренды автомобиля?",
      title_ru: "Минимальный возраст для аренды автомобилей — 25 лет. Водители моложе 25 лет должны иметь стаж вождения более 2 лет.",
      title_en: "Drivers under 25 must have a license for a minimum of three years. The same applies for the person(s) whose name(s) is/are mentioned as additional driver.",
    },
    {
      btn_en: "What do you need for a luxury car rental in Dubai ?",
      btn_ru:"Какие документы нужны для аренды автомобиля?",
      title_ru: "Паспорт, водительское удостоверение – обратите внимание, что мы принимаем только оригиналы данных документов.",
      title_en: "Drivers shall be required to have a valid driver's license and Passport copy.",
    },
    {
      btn_en: "How much is the Insurance limit on luxury car rental Dubai?",
      btn_ru:"Сколько стоит страховой лимит при аренде автомобилей класса люкс в Дубае?",
      title_ru: "Включает в себя полную страховку транспортного средства. 3000–5000 дирхамов ОАЭ за автомобили повышенной комфортности. 7000-10000 дирхамов ОАЭ за спортивные автомобили.",
      title_en: "Includes an overall Motor Vehicle Insurance. 3000-5000 AED for Excess Luxury Cars. 7000-10000 AED for Sports Cars."
    },
    {
        btn_en: "What are the driving licenses that can be used in Arab countries ?",
        btn_ru:"Какие водительские права можно использовать в арабских странах?",
        title_en: "Local driving license for UAE citizens. International driving licenses issued by the following countries: 1. Kingdom of Saudi Arabia, 2. Egypt, 3. Bahrain, 4. Jordan, 5. Kuwait, 6. Tunisia, 7. Sultanate of Oman, 8. Algeria, 9. Qatar, 10. Morocco, 11. Sudan, 12. Somalia, 13. Palestine, 14. Lebanon, 15. Libya, 16. Syria, 17 Yemen, 18. Iraq, 19. Djibouti, 20. Comoros, 21. Mauritania.",
        title_ru: "Местные водительские права для граждан ОАЭ. Международные водительские права, выданные следующими странами: 1. Королевство Саудовская Аравия, 2. Египет, 3. Бахрейн, 4. Иордания, 5. Кувейт, 6. Тунис, 7. Султанат Оман, 8. Алжир, 9. Катар, 10. Марокко, 11. Судан, 12. Сомали, 13. Палестина, 14. Ливан, 15. Ливия, 16. Сирия, 17. Йемен, 18. Ирак, 19. Джибути, 20. Коморские Острова, 21. Мавритания."
    },
    {
        btn_en: "Can anyone else drive the car i rent?",
        btn_ru:"Может ли кто-нибудь еще управлять автомобилем, который я арендую?",
        title_ru: "В договоре прописываются два водителя, но на момент оформления договора необходимо предоставить водительское удостоверение и паспорт.",
        title_en: "The contract prescribes two drivers, but at the time of filling out the contract, you must provide a driver's license and passport."
    }
  ];


  const handleAccordionClick = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className='faq_accardion_box'>
      {AccardionDate.map((item, i) => (
        <div className='accardion__box' key={i}>
          <button className='faq_btn' onClick={() => handleAccordionClick(i)}>
            <svg
              className={`${openIndex === i ? ' accordion-arrow ' : null}  accordion-action `}
              width='32'
              height='32'
              viewBox='0 0 32 32'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path d='M13 10L21 16.5L13 23L17.5714 16.5L13 10Z' fill='white'></path>
              <circle cx='16' cy='16' r='15.5' stroke='white'></circle>
            </svg>
            <p>{item[`btn_${currentLanguage}`]}</p>
          </button>
          {openIndex === i ? (
            <div className='faq_accardion_rec'>
              <p>{item[`title_${currentLanguage}`]}</p>
            </div>
          ) : null}
          <div className='accardion_bottom'></div>
        </div>
      ))}
    </div>
  );
};

export default Accordion;
