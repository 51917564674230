import React, { useContext, useState } from 'react'
import "./FollowInstagram.css";

import limeLambo from "../../assets/images/limeLambo.webp";
import greenLambo from "../../assets/images/greenLambo.webp";
import greenLambowithman from "../../assets/images/glambowithman.webp"
import blueMclearn from "../../assets/images/blueMClearn.webp";
import whiteLambo from "../../assets/images/whitelambo.webp";
import yellowLambo from "../../assets/images/yellowlambo.webp";
import FollowModal from '../../components/FollowModal/FollowModal';
import { Context } from '../../components/Contexs/ContexProvider';
import {useTranslation} from "react-i18next"

function FollowInstagram() {

    const { setHandleModal, handleModal } = useContext(Context);

    const [formodal, setFormodal] = useState({});
    const {t} = useTranslation()

    const followData = [
        {
            id:1,
            title: "For Puture",
            imager_path: limeLambo
        }, {
            id:2,
            title: "For Puture",
            imager_path: greenLambo
        }, {
            id:3,
            title: "For Puture",
            imager_path: greenLambowithman
        },{
            id:4,
            title: "For Puture",
            imager_path: blueMclearn
        },{
            id:5,
            title: "For Puture",
            imager_path: whiteLambo
        },{
            id:6,
            title: "For Puture",
            imager_path: yellowLambo
        },
    ]

   function modalFunc(mid) {
        followData.map((item) => {
            if(item.id === Number(mid)) {
               setFormodal(item)
            }
        })

        setHandleModal(!handleModal)
    }

  return (
    <>
        <div className="follow">
            <div className="container">
                <h2 className="follow_title">{t('followTitle')}</h2>
                <div className="follow_flex">
                    {
                        followData.map((data) => {
                            return (
                                <div className="followCards" key={data.id} onClick={() => modalFunc(data.id)}>
                                    <img className='following_page_pic' src={data.imager_path} alt="" />
                                </div>
                            )
                        })
                    }
                </div>
                
            </div>
        </div>
        <FollowModal picture={formodal.imager_path} open={handleModal} />
    </>
  )
}

export default FollowInstagram